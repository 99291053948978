/* Universal Css Start Here */
body {
	background-color: var(--body-color);
	font-family: var(--font-default);
	color: var(--default-text-color);
}
a {
	text-decoration: none !important;
	color: var(--theme-color-2) !important;
}
button {
	font-family: var(--font-btn);
}
figure {
	margin-bottom: 0 !important;
}
.primary-btn {
	border-radius: 0;
	background: var(--primary-btn-color);
	outline: none;
	border: 3px solid var(--primary-btn-color);
	font-size: 16px;
	color: var(--primary-btn-text-color) !important;
	text-align: center;
	transition: 0.5s;
	padding: 8px 25px;
	text-decoration: none !important;
	cursor: pointer;
	font-family: "poppins-medium";
}
.primary-btn:hover {
	background-color: var(--primary-btn-hover-back);
	color: var(--primary-btn-text-color);
}
/* Universal Css End Here */

/* Typography Start Here */
h1 {
	font-family: var(--font-heading);
	font-size: 40px !important;
	line-height: 50px !important;
}
h2 {
	font-family: var(--font-heading);
	font-size: 32px !important;
	line-height: 42px !important;
}
h3 {
	font-family: var(--font-poppins-semibold);
	font-size: 22px !important;
	line-height: 32px !important;
}
h4 {
	font-family: var(--font-default);
	font-size: 20px !important;
	line-height: 30px !important;
}
h5 {
	font-family: var(--font-default);
	font-size: 18px !important;
	line-height: 28px !important;
}
h6 {
	font-family: var(--font-default);
}
p {
	font-family: var(--font-paragraph);
	font-size: 16px !important;
	line-height: 26px !important;
}
@media (max-width: 1150px) {
	h1 {
		font-size: 30px !important;
		line-height: 40px !important;
	}
}
@media (max-width: 991px) {
	h1 {
		font-size: 26px !important;
		line-height: 36px !important;
	}
	h2 {
		font-size: 25px !important;
		line-height: 35px !important;
	}
	h3 {
		font-size: 17px !important;
		line-height: 27px !important;
	}
	h4 {
		font-size: 16px !important;
		line-height: 26px !important;
	}
}
@media (max-width: 768px) {
	h1 {
		font-size: 20px !important;
		line-height: 30px !important;
	}
	h2 {
		font-size: 20px !important;
		line-height: 30px !important;
	}
}
@media (max-width: 414px) {
	h1 {
		font-size: 16px !important;
		line-height: 26px !important;
	}
	h2 {
		font-size: 20px !important;
		line-height: 30px !important;
	}
	h4 {
		font-size: 13px !important;
		line-height: 23px !important;
	}
}
/* Typography End Here */

/* Not Found Css Start Here */
.not-found {
	padding: 60px 0;
}
.not-found .img-box img {
	width: 100%;
}
/* Not Found Css End Here */

/* InnerBanner Css Start Here */
.innerbanner_sec {
	position: relative;
}
.innerbanner_sec .title {
	text-align: center;
	position: absolute;
	bottom: 0;
	height: 100%;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.innerbanner_sec .title h1 {
	font-size: 55px;
	color: #fff;
}
.innerbanner_sec .img-box img {
	height: 280px;
	object-fit: cover;
	object-position: center center;
	width: 100%;
	filter: brightness(0.7);
}
@media (max-width: 991px) {
	.innerbanner_sec .title h1 {
		font-size: 55px;
		line-height: 65px;
	}
}
@media (max-width: 575px) {
	.innerbanner_sec .img-box img {
		height: 200px;
	}
	.innerbanner_sec .title h1 {
		font-size: 30px;
		line-height: 40px;
	}
}
/* InnerBanner Css End Here */

/* 404 Not Found Css Start Here */
.not-found {
	padding: 60px 0;
}
.not-found .img-box img {
	width: 100%;
}
/* 404 Not Found Css End Here */

/* Faqs Sec Css Start Here */
.faqs_sec {
	padding: 50px 0;
}
.faqs_sec .card {
	border: 1px solid #ddd;
	border-radius: 0;
	margin-bottom: 15px;
}
.faqs_sec .card .card-header {
	border-bottom: 0;
	border-radius: 0;
	background: transparent;
	padding: 0;
}
.faqs_sec .card .card-header button {
	box-shadow: none;
	outline: none;
	color: #000000;
	border-radius: 0;
	padding: 18px 18px;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.faqs_sec .card .card-header button[aria-expanded="true"] i:before {
	content: "\f068";
}
.faqs_sec .card .card-body {
	font-size: 14px;
	color: #969696;
	font-family: "poppins-regular";
	line-height: 26px;
}
.faqs_sec .card .card-header button[aria-expanded="true"] {
	background: #f6a309;
	color: #fff;
}
/* Faqs Sec Css End Here */

/* Terms & Condition Css Start Here */
.terms_condition {
	padding: 40px 0;
}
.terms_condition .legal_content h2 {
	text-align: center;
	padding: 50px 0;
}
.terms_condition .legal_content h3 {
	padding: 20px 0;
}
.terms_condition .legal_content p {
	font-size: 15px;
	color: #929292;
}
@media (max-width: 575px) {
	.terms_condition {
		padding: 40px 0 0;
	}
	.terms_condition .legal_content h2 {
		text-align: left;
		padding: 10px 0;
	}
}
/* Terms & Condition Css End Here */

/* Product Css Start Here */
.product_sec {
	padding: 60px 0;
}
.product-box .img-box img {
	height: 350px;
	width: 100%;
	object-position: center center;
}
.product-box {
	position: relative;
	margin-bottom: 30px;
	overflow-y: hidden;
}
.product-box .detail {
	position: absolute;
	bottom: 0;
	padding: 15px 15px;
	width: 100%;
	background: #0000009c;
	color: #fff;
	height: 30%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	transition: 0.6s;
}
.product-box .detail .price {
	margin: 0;
}
.product-box:hover .detail {
	height: 100%;
}
.product-box .detail .button-group {
	margin-top: 10px;
	position: absolute;
	transition: 0.6s;
	bottom: -100%;
}
.product-box .detail .button-group a {
	background: #f6a309;
	width: 45px;
	height: 45px;
	border-radius: 50%;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-box .detail .button-group button {
	background: #f6a309;
	width: 45px;
	height: 45px;
	border-radius: 50%;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
.product-box:hover .detail .button-group {
	bottom: 25%;
}
ul.pagination {
	padding: 30px 0 0;
	margin: 0;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: center;
}
ul.pagination li {
	margin: 0 5px;
}
ul.pagination li a {
	background: #000;
	width: 30px;
	display: flex;
	height: 30px;
	border-radius: 50%;
	color: #fff !important;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	transition: 0.6s;
}
ul.pagination li a.active {
	background: #f99e1c;
}
@media (max-width: 768px) {
	.product-box .img-box img {
		height: 320px;
	}
}
@media (max-width: 575px) {
	.product-box .detail {
		height: 45%;
	}
	.product-box .detail .button-group {
		position: unset;
	}
}
/* Product Css End Here */

/* Header Css Start Here */
header.top-header {
	padding: 20px 20px;
}
header.top-header .menus {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
ul.for-mobile {
	display: none;
}
header.top-header .menus li {
	padding: 5px 0;
}
header.top-header .menus li a {
	padding: 10px 0px;
	color: #000;
	font-size: 13px;
	font-family: var(--font-poppins-semibold);
	text-decoration: none;
	transition: 0.6s;
	position: relative;
}
header.top-header .profile-link a::before {
	display: none;
}
header.top-header .menu-items .icon {
	max-width: 35px;
}
header.top-header .menus li a:hover {
	color: var(--theme-color-1);
}
header.top-header .menus li a:before {
	content: "";
	background: var(--theme-color-1);
	width: 0%;
	height: 2px;
	position: absolute;
	bottom: 0;
	left: 0;
	transition: 0.6s;
}
header.top-header .menus li a.active {
	color: var(--theme-color-1);
}
header.top-header .menus li a.active:before {
	width: 100%;
}
header.top-header .menus li a:hover:before {
	width: 100%;
}
header.top-header .menus li.cart-icon {
	border-left: 1px solid #8a8a8a;
}
header.top-header .menus li.cart-icon a {
	background: var(--theme-color-1);
	display: flex;
	padding: 7px 7px 7px 7px;
	position: relative;
	/* width: 80px; */
	justify-content: space-between;
	margin-left: 10px;
}
header.top-header .menus li.cart-icon a .icon {
	margin-top: 3px;
}
header.top-header .menus li.cart-icon a .count {
	color: var(--theme-color-1);
	background: #fff;
	border-radius: 50%;
	font-weight: 700;
	font-size: 12px;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
}
header.top-header .menus li.menu-items.dropdown:hover .dropdown-menu {
	display: block;
}
header.top-header .menus li.menu-items .dropdown-menu {
	border: 1px solid #ddd;
	border-radius: 0;
	padding: 0;
	margin-top: 3px;
	left: unset;
	right: 0;
}
header.top-header .menus li.menu-items .dropdown-menu li a {
	border-bottom: 1px solid #ddd;
	padding: 10px 10px;
	background: transparent;
}
header.top-header .menus li.menu-items .dropdown-menu li {
	padding: 0;
	position: relative;
}
header.top-header .menus li.menu-items.dropdown li.inner-dropdown ul {
	position: absolute;
	right: -202px;
	top: -1px;
	display: none;
}
header.top-header
	.menus
	li.menu-items
	.dropdown-menu
	li.inner-dropdown:hover
	ul.dropdown-innermenu {
	display: block;
}
header.top-header .menus li.menu-items .dropdown-menu li .dropdown-innermenu {
	padding: 0;
	margin: 0;
	list-style: none;
	background: #fff;
}
header.top-header
	.menus
	li.menu-items
	.dropdown-menu
	.dropdown-innermenu
	li
	a:before {
	display: none;
}
header.top-header .menus li.menu-items.dropdown li.inner-dropdown ul li {
	padding: 10px 0;
}
header.top-header .menus li.menu-items.dropdown li.inner-dropdown ul li a {
	display: block;
}
header.top-header .menus li.menu-items.dropdown li.inner-dropdown ul li {
	padding: 0;
}
header.top-header
	.menus
	li.menu-items
	.dropdown-menu
	li.inner-dropdown:hover
	ul.dropdown-innermenu {
	display: block;
}
@media (max-width: 1150px) {
	header.top-header .menus li a {
		font-size: 10px;
	}
}
@media (max-width: 991px) {
	header.top-header {
		padding: 10px 0px;
	}
	ul.for-mobile {
		display: flex;
	}
	header.top-header ul.menus {
		display: none;
	}
	header.top-header ul.for-mobile {
		padding: 0;
		margin: 0;
		list-style: none;
		display: flex;
		align-items: center;
		justify-content: end;
	}
	header.top-header ul.for-mobile li.cart-icon {
		border-left: 1px solid #8a8a8a;
		margin-left: 15px;
	}
	header.top-header ul.for-mobile li.cart-icon a {
		background: var(--theme-color-1);
		display: flex;
		padding: 7px 7px 5px 7px;
		position: relative;
		width: 80px;
		justify-content: space-between;
		margin-left: 10px;
	}
	header.top-header ul.for-mobile li.cart-icon a .icon {
		margin-top: -2px;
	}
	header.top-header ul.for-mobile li.cart-icon a span.count {
		color: var(--theme-color-1);
		background: #fff;
		border-radius: 50%;
		font-weight: 700;
		font-size: 12px;
		width: 25px;
		height: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	header.top-header ul.for-mobile button.canvas-btn {
		border: 0;
		box-shadow: none;
		padding: 0;
		background: transparent;
		outline: none;
		margin-top: 8px;
	}
}
/* Header Css End Here */

/* Mobile Css start Here */
.mobile-header .cancel-btn {
	border: 0;
	box-shadow: none;
	outline: none;
	background: #fff;
	padding: 10px 10px 5px;
	position: absolute;
	top: 20px;
	right: 20px;
}
.mobile-header {
	background: #f6a309;
	position: fixed;
	top: 0;
	z-index: 2;
	width: 30%;
	height: 100%;
	right: 0;
	transition: 0.6s;
	display: flex;
	align-items: center;
	transform: translateX(150%);
	padding: 15px 25px;
}
.mobile-header.show {
	transform: translateX(0%);
	display: flex;
}
.mobile-header ul.mobile-menus {
	padding: 0;
	margin: 0;
	list-style: none;
	overflow-y: scroll;
}
.mobile-header .mobile-menus .menu-items {
	margin: 10px 0;
}
.mobile-header .mobile-menus .menu-items a {
	color: #fff !important;
	font-size: 17px;
	font-family: "poppins-regular";
}
.mobile-header ul.mobile-menus .card {
	background: transparent;
	border: 0;
	padding: 0;
}
.mobile-header ul.mobile-menus .card .card-header {
	padding: 0;
	margin: 0;
	border: 0;
	background: transparent;
}
.mobile-header ul.mobile-menus .card .card-header .btn {
	padding: 0;
	box-shadow: none;
	outline: none;
	border: 0;
	text-decoration: none;
}
.mobile-header ul.mobile-menus .card .card-header .btn i {
	margin-left: 20px;
	color: #fff;
}
.mobile-header ul.mobile-menus .card .card-body {
	padding: 10px 10px;
}
.mobile-header ul.mobile-menus .card .card-body ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
.mobile-header ul.mobile-menus .card .card-body ul li a {
	font-size: 15px;
}
@media (max-width: 768px) {
	.mobile-header {
		width: 50%;
	}
}
@media (max-width: 480px) {
	.mobile-header {
		width: 100%;
	}
}
/* Mobile Css End Here */

/* Modal Css Start Here */
#bookDemo .modal-dialog {
	max-width: 45%;
}
#bookDemo .modal-body {
	padding: 45px 45px;
}
#bookDemo .modal-body .form-group .form-control {
	border-radius: 0;
	border: 1px solid #ddd;
	height: 45px;
	font-size: 14px;
	font-family: "poppins-regular";
}
#bookDemo .modal-body textarea.form-control {
	height: 100px !important;
	resize: none;
}
#bookDemo .modal-body .heading-wrapper {
	text-align: center;
	padding-bottom: 25px;
}
#bookDemo .modal-body button {
	width: 100%;
	display: block;
	cursor: pointer;
}
/* Modal Css End Here */

/* Banner Sec Css Start Here */
.banner_sec {
	padding: 40px 0;
	text-align: center;
	background-image: url("../img/banner-img.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	height: 75vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.banner_sec .content-wrapper {
	background: #ffffff61;
	padding: 50px 0 60px;
	backdrop-filter: blur(5px);
}
.banner_sec h4 {
	max-width: 40%;
	margin: 0px auto;
}
.banner_sec .button-group {
	margin-top: 30px;
}
.tagline {
	padding: 100px 0;
	text-align: center;
	background-color: #f4f4f4;
}
.tagline .content {
	max-width: 80%;
	margin: 0px auto;
}
.tagline .content h4 {
	max-width: 50%;
	margin: 20px auto 0;
}
@media (max-width: 1150px) {
	.banner_sec .content-wrapper {
		padding: 50px 25px 60px;
	}
	.banner_sec h4 {
		max-width: 70%;
	}
	.tagline .content h4 {
		max-width: 80%;
	}
}
@media (max-width: 991px) {
	.tagline {
		padding: 60px 0;
	}
	.tagline .content {
		max-width: 100%;
	}
	.tagline .content h4 {
		max-width: 80%;
	}
}
@media (max-width: 768px) {
	.banner_sec {
		height: 50vh;
	}
	.banner_sec h4 {
		max-width: 100%;
		font-size: 17px;
	}
	.tagline {
		padding: 30px 0;
	}
	.tagline .content h4 {
		max-width: 100%;
		font-size: 16px;
		line-height: 26px;
	}
}
@media (max-width: 414px) {
	.banner_sec {
		height: 50vh;
	}
	.banner_sec h4 {
		font-size: 13px;
		line-height: 23px;
	}
	.banner_sec .content-wrapper {
		padding: 20px 25px 30px;
	}
	.tagline .content h4 {
		font-size: 13px;
		line-height: 23px;
	}
}
/* Banner Sec Css End Here */

/* Range Sec Css Start Here */
.range-product {
	background: #ebe4d8;
	padding: 80px 0;
}
.range-product .heading-wrapper {
	padding-bottom: 50px;
	text-align: center;
	max-width: 90%;
	margin: 0px auto;
}
.software-product-box {
	background: #fff;
	padding: 40px 30px 50px;
	transition: 0.6s;
	position: relative;
	z-index: 2;
	overflow: hidden;
}
.software-product-box:before {
	content: "";
	background: var(--theme-color-1);
	width: 100%;
	height: 200%;
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 0px 0px 200px 200px;
	transform: translateY(-100%);
	transition: 1s;
	z-index: -1;
}
.software-product-box:hover:before {
	transform: translateY(0%);
}
.software-product-box .icon-box {
	max-width: 100px;
}
.software-product-box .icon-box img {
	transition: 0.6s;
}
.software-product-box:hover .icon-box img {
	filter: brightness(0);
}
.software-product-box .content {
	padding-top: 25px;
}
.software-product-box .content .title {
	border-bottom: 2px solid #000;
	padding-bottom: 10px;
	max-width: 85%;
	margin-bottom: 20px;
	transition: 0.6s;
}
.software-product-box .content .title {
	color: #000;
	transition: 0.6s;
}
.software-product-box .content p {
	font-size: 14px !important;
	margin-bottom: 10px;
	transition: 0.6s;
}
.software-product-box .content .colored {
	transition: 0.6s;
	color: var(--theme-color-1);
}
.software-product-box:hover .content .colored {
	color: #000;
}
.software-product-box .button-group {
	margin-top: 30px;
	text-align: center;
}
.software-product-box .button-group a {
	transition: 1s;
}
.software-product-box:hover .button-group a {
	border-color: #000;
	background-color: transparent;
	color: #000;
}
@media (max-width: 1150px) {
	.software-product-box {
		padding: 30px 15px 40px;
	}
	.software-product-box .content .title {
		max-width: 100%;
	}
	.software-product-box .content p {
		font-size: 12px;
		line-height: 20px;
	}
}
@media (max-width: 991px) {
	.range-product {
		padding: 50px 0;
	}
	.software-product-box {
		max-width: 45%;
		margin: 0px auto 10px;
	}
}
@media (max-width: 768px) {
	.software-product-box {
		max-width: 70%;
	}
	.software-product-box .content p {
		font-size: 15px;
		line-height: 25px;
	}
}
@media (max-width: 414px) {
	.range-product .heading-wrapper {
		max-width: 100%;
		padding-bottom: 20px;
	}
	.software-product-box {
		max-width: 100%;
	}
}
/* Range Sec Css End Here */

/* IPC Sec Css Start Here */
.ipc_sec {
	padding: 90px 0 60px;
	background: #f4f4f4;
}
.ipc_sec ul.points {
	padding: 0;
	margin: 20px 0;
	list-style: none;
}
.ipc_sec ul.points li {
	margin-bottom: 6px;
	font-size: 18px;
	color: #000;
	position: relative;
	padding-left: 30px;
}
.ipc_sec ul.points li:before {
	width: 12px;
	height: 12px;
	background: var(--theme-color-1);
	border-radius: 50%;
	position: absolute;
	content: "";
	left: 0;
	top: 9px;
}
.ipc_sec .img-box {
	padding-left: 50px;
}
.ipc_sec .button-group {
	margin-top: 35px;
}
@media (max-width: 991px) {
	.ipc_sec {
		padding: 60px 0 60px;
	}
	.ipc_sec .img-box {
		padding-left: 0px;
		padding-top: 80px;
	}
}
@media (max-width: 991px) {
	.ipc_sec ul.points li {
		font-size: 16px;
	}
}
/* IPC Sec Css End Here */

/* Trusted Sec Css Start Here */
.brands_sec {
	padding: 80px 0 40px;
}
.brands_sec .heading-wrapper {
	padding-bottom: 30px;
	text-align: center;
	max-width: 60%;
	margin: 0px auto;
}
.brands_sec .text-wrapper {
	border-bottom: 1px solid #8a8a8a;
}
.brands_sec .brands-slider {
	margin-top: 60px;
}
.brands_sec .swiper-slide {
	height: 150px !important;
	display: flex;
	align-items: center;
}
.brands_sec .swiper-pagination {
	bottom: 0;
	top: unset;
	left: 0;
	right: 0;
	margin: 0px auto;
	display: table;
	z-index: 999;
}
.brands_sec .brands-slider .swiper-horizontal {
	padding-bottom: 30px;
	overflow-x: hidden;
	overflow-y: auto !important;
}
.brands_sec .swiper-pagination .swiper-pagination-bullet {
	height: 11px;
	width: 11px;
}
.brands_sec .swiper-pagination .swiper-pagination-bullet-active {
	background: var(--theme-color-1);
	border-color: var(--theme-color-1) !important;
}
@media (max-width: 991px) {
	.brands_sec .heading-wrapper {
		max-width: 100%;
	}
}
@media (max-width: 768px) {
	.brands_sec {
		padding: 40px 0 40px;
	}
	.brands_sec .brands-slider {
		margin-top: 0px;
	}
}
/* Trusted Sec Css End Here */

/* Blog Sec Css Start Here */
.blog_sec {
	padding: 80px 0 60px;
	background: #f4f4f4;
}
.blog_sec .heading-wrapper {
	display: flex;
	align-items: center;
	padding-bottom: 50px;
	justify-content: space-between;
}
.blog_sec .heading-wrapper h3 {
	font-size: 28px;
	font-family: var(--font-default);
	line-height: 38px;
	position: relative;
	padding-top: 10px;
}
.blog_sec .heading-wrapper .button-group {
	flex: 0 0 40%;
	text-align: end;
}
.blog_sec .heading-wrapper h3:before {
	content: "";
	background: #000;
	width: 100px;
	height: 3px;
	position: absolute;
	left: 0;
	top: -10px;
}
.blog_sec a {
	color: #000;
	text-decoration: none;
}
.blog-box {
	background: #fff;
	padding: 12px 12px;
	border: 1px solid #ddd;
	transition: 0.6s;
}
.blog-box:hover {
	background: #003b7a;
}
.blog-box .blog-image img {
	height: 210px;
	width: 100%;
	object-fit: cover;
	object-position: center center;
	border: 1px solid #ddd;
	transition: 0.6s;
}
.blog-box .blog-content {
	padding-top: 12px;
	padding-bottom: 10px;
}
.blog-box .blog-content .title {
	font-family: var(--font-heading);
	font-size: 18px;
	line-height: 28px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: 0.6s;
	min-height: 56px;
}
.blog-box:hover .blog-content .title {
	color: #fff;
}
.blog-box .blog-content p {
	margin-top: 10px;
	font-size: 14px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 24px;
	transition: 0.6s;
}
.blog-box:hover .blog-content p {
	color: #fff;
}
.blog-box .blog-content .button-group {
	margin-top: 20px;
}
.blog-box .blog-content .button-group a {
	font-size: 15px;
	text-decoration: underline;
	font-family: "poppins-regular";
}
.blog-box:hover .blog-content .button-group a {
	color: #fff !important;
}
.blog_sec .swiper-pagination {
	top: unset;
	bottom: 0px;
}
.blog_sec .swiper.swiper-initialized {
	padding-bottom: 60px;
}
.blog_sec .swiper-pagination span.swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	border: 1px solid #000;
	background: transparent;
	opacity: 0.7;
}
.blog_sec .swiper-pagination span.swiper-pagination-bullet-active {
	background: var(--theme-color-1);
	border-color: var(--theme-color-1);
	opacity: 1;
}
.news_sec {
	padding: 60px 0;
}
.news_sec .heading_wrapper {
	padding-bottom: 10px;
	text-align: center;
	border-bottom: 2px solid #000;
	margin-bottom: 50px;
}
.news_sec .blog-box {
	margin-bottom: 15px;
}
.news-detail {
	padding: 60px 0;
}
.news-detail .news_detail_box {
	background: #f8f8f8;
	padding: 20px 20px;
	border-radius: 10px;
}
.news-detail .news_detail_box ul.breadcrumbs {
	padding: 0;
	margin: 0 0 20px 0;
	list-style: none;
}
.news-detail .news_detail_box ul.breadcrumbs li {
	display: inline-block;
	margin-right: 5px;
	color: #838383;
	font-size: 15px;
}
.news-detail .news_detail_box ul.breadcrumbs li a {
	color: #f6a309;
}
.news-detail .news_detail_box .news-image img {
	width: 100%;
	height: 380px;
	object-fit: cover;
	object-position: center;
}
.news-detail .news_detail_box .content-wrapper {
	padding-top: 20px;
}
.news-detail .news_detail_box .heading-wrapper {
	padding: 20px 0;
}
.news-detail .news_detail_box .heading-wrapper .date {
	font-size: 15px;
	color: #f6a309;
}
.news-detail .news_detail_box .heading-wrapper .date i {
	margin-right: 8px;
}
.news-detail .news_detail_box .heading-wrapper h2 {
	font-size: 30px;
	line-height: 40px;
}
.news-detail .news_detail_box p {
	font-size: 15px;
	line-height: 25px;
	color: #575757;
}
.news-detail .news_detail_box .passage h4 {
	padding: 10px 0;
	font-family: "poppins-medium";
}
.news-detail .news_detail_box .passage a {
	color: #f99e1c;
}
@media (max-width: 1150px) {
	.blog_sec .heading-wrapper h3 {
		font-size: 24px;
		line-height: 34px;
	}
}
@media (max-width: 991px) {
	.blog_sec .heading-wrapper {
		display: block;
		text-align: center;
	}
	.blog_sec .heading-wrapper .button-group {
		text-align: center;
		margin-top: 50px;
	}
	.blog_sec .heading-wrapper h3:before {
		left: 0;
		right: 0;
		margin: 0px auto;
		bottom: -20px;
		top: unset;
	}
}
@media (max-width: 768px) {
	.blog_sec {
		padding: 30px 0 30px;
		background: #f4f4f4;
	}
	.blog_sec .heading-wrapper h3 {
		font-size: 20px;
		line-height: 30px;
	}
	.news-detail {
		padding: 40px 0 0;
	}
	.news-detail .news_detail_box ul.breadcrumbs li {
		display: block;
		margin: 0 0 8px;
	}
	.news-detail .news_detail_box .heading-wrapper h2 {
		font-size: 20px;
		line-height: 30px;
	}
	.news-detail .news_detail_box p {
		font-size: 13px;
		line-height: 22px;
	}
}
@media (max-width: 575px) {
	.news-detail .news_detail_box .news-image img {
		height: 300px;
	}
}
@media (max-width: 414px) {
	.blog_sec .heading-wrapper h3 {
		font-size: 18px;
		line-height: 28px;
	}
	.news-detail .news_detail_box ul.breadcrumbs li {
		font-size: 12px;
	}
	.news-detail .news_detail_box .news-image img {
		height: 200px;
	}
	.news-detail .news_detail_box .heading-wrapper h2 {
		font-size: 16px;
		line-height: 26px;
	}
}
/* Blog Sec Css End Here */

/* Newsletter Css Start Here */
.newsletter {
	padding: 60px 0;
}
.newsletter .heading-wrapper {
	text-align: center;
}
.newsletter .newsletter-box {
	max-width: 55%;
	margin: 30px auto 0px;
}
.newsletter .newsletter-box form {
	display: flex;
	position: relative;
}
.newsletter .newsletter-box .form-group {
	flex: 1;
}
.newsletter .newsletter-box .form-group .form-control {
	height: 60px;
	padding: 10px 150px 10px 25px;
	border-radius: 50px;
	box-shadow: none;
	outline: none;
}
.newsletter .newsletter-box .form-group .form-control:focus {
	border-color: #f6a309;
}
.newsletter .newsletter-box button {
	position: absolute;
	right: 10px;
	top: 7px;
	border-radius: 50px;
	padding: 8px 35px;
	cursor: pointer;
}
@media (max-width: 768px) {
	.newsletter .newsletter-box {
		max-width: 100%;
	}
}
@media (max-width: 414px) {
	.newsletter .newsletter-box form {
		display: block;
	}
	.newsletter .newsletter-box .form-group .form-control {
		padding-right: 25px;
	}
	.newsletter .newsletter-box button {
		position: unset;
		width: 100%;
	}
}
/* Newsletter Css End Here */

/* Footer Css Start Here */
footer {
	background-image: linear-gradient(
			rgba(255, 255, 255, 0.95),
			rgba(255, 255, 255, 0.95)
		),
		url("../img/footer-bg.png");
	background-size: cover;
	background-repeat: no-repeat;
	padding: 70px 0 25px;
}
footer .footer-content .logo-box {
	padding-bottom: 35px;
}
footer .footer-content ul.info {
	padding: 0;
	margin: 0;
	list-style: none;
	max-width: 70%;
}
footer .footer-content ul.info li {
	margin-bottom: 15px;
}

footer .footer-content ul.info li a {
	font-size: 14px;
	text-decoration: none;
	font-family: "poppins-medium";
}
footer .footer-content .social-icons a i {
	font-size: 22px;
}
footer .footer-content .social-icons .seprator {
	margin: 0 10px;
	border: 1px solid #000;
	height: 27px;
}
footer .footer-content .social-icons {
	display: flex;
	align-items: center;
}
footer .note {
	padding: 40px 0;
}
footer .note p {
	font-size: 13px;
	line-height: 22px;
}
footer .footer-content .menu-links {
	padding: 0;
	list-style: none;
	margin: 0;
	border-left: 1px solid #000;
	padding-left: 15px;
}
footer .footer-content .menu-links li {
	margin-bottom: 15px;
}
footer .footer-content .menu-links li a {
	font-weight: 700;
	font-size: 18px;
	color: #000;
	transition: 0.6s;
	text-decoration: none;
}
footer .footer-content .menu-links li a:hover {
	color: #f99e1c;
}
footer .copyright {
	border-top: 1px solid #000;
	padding: 20px 0 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
footer .copyright .rights {
	font-size: 14px;
	margin: 0;
}
@media (max-width: 991px) {
	footer .footer-content {
		margin-bottom: 50px;
	}
	footer .note {
		padding: 0;
	}
}
@media (max-width: 768px) {
	footer .copyright {
		justify-content: center;
		flex-direction: column-reverse;
	}
	footer .copyright .img-box {
		padding-bottom: 15px;
	}
}
@media (max-width: 414px) {
	footer .note p {
		font-size: 13px;
		line-height: 22px;
		text-align: justify;
	}
}
/* Footer Css End Here */
