/* Lead Course Css Start Here */
.course_feature {
	padding: 60px 0;
}
.course_feature .content-wrapper {
	padding: 42px 40px 75px 40px;
	background: #f7f7f7;
	margin-top: 20px;
}
.course_feature .content-wrapper .heading-wrapper {
	padding-bottom: 10px;
}
.course_feature .content-wrapper .heading-wrapper .title {
	margin: 0;
}
.course_feature .content-wrapper ul.points {
	padding: 0;
	margin: 0;
	list-style: none;
}
.course_feature .content-wrapper .details-and-price {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.course_feature .content-wrapper ul.points li {
	margin-bottom: 6px;
	position: relative;
	padding-left: 20px;
	font-size: 14px;
}
.course_feature .content-wrapper ul.points li:before {
	content: "";
	background: var(--theme-color-1);
	height: 8px;
	width: 8px;
	position: absolute;
	left: 0;
	top: 6px;
	border-radius: 50%;
}
.course_feature .content-wrapper .right-box {
	text-align: center;
}
.course_feature .content-wrapper .right-box .price {
	padding-bottom: 15px;
}
.course_feature .content-wrapper .right-box .price h2 {
	color: var(--theme-color-1);
	font-family: var(--font-poppins-semibold);
}
.course_feature .img-box {
	margin-right: -25%;
}
.value-box {
	border: 1px solid #ddd;
	padding: 20px 20px;
	height: 400px;
	background: #f6a3091a;
}
.core-value {
	padding: 60px 0;
}
.value-box .icon-box {
	max-width: 100px;
	margin-bottom: 25px;
}
.value-box .icon-box img {
	width: 100px;
	height: 100px;
}
.value-box .content {
	border-top: 1px solid var(--theme-color-1);
	padding-top: 20px;
}
.value-box .content .name {
	color: #000;
}
.value-box .content p {
	font-size: 14px;
	line-height: 24px;
	display: -webkit-box;
	-webkit-line-clamp: 7;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.owl-nav button {
	background: var(--theme-color-1) !important;
	color: #fff !important;
	width: 35px;
	height: 35px;
	margin: 0 2px;
	border-radius: 50%;
	font-size: 23px !important;
	line-height: 37px !important;
}
.owl-nav button.owl-prev {
	position: absolute;
	left: -25px;
	top: 50%;
}
.owl-nav button.owl-next {
	position: absolute;
	right: -25px;
	top: 50%;
}
.subscription_sec {
	padding: 30px 0 60px;
}
.subscription_sec .subscribe-box {
	background: #ffdfaf;
	padding: 60px 55px 60px 55px;
	text-align: center;
}
.contact_form {
	padding: 40px 0 60px;
}
.contact_form .form-box {
	position: relative;
	padding: 35px 35px 25px 35px;
	box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
	background: #fff;
	z-index: 2;
	margin-right: -30%;
}
.contact_form .form-box .title-wrapper {
	padding-bottom: 20px;
	text-align: center;
}
.contact_form .form-box .form-group .form-control {
	border-radius: 0;
	border: 0;
	border-bottom: 1px solid #ddd;
	box-shadow: none;
	outline: none;
	padding: 0 0 5px;
	font-size: 13px;
	color: #000;
}
.contact_form .form-box .form-group label {
	font-size: 14px;
	color: #757575;
}
.contact_form .form-box .form-check {
	display: flex;
	align-items: center;
	background: #f6f6f1;
	border: 1px solid #8a8a8a;
	width: 100%;
	padding: 15px 15px 15px 15px;
}
.contact_form .form-box .form-check input {
	width: auto;
}
.contact_form .form-box .form-check label {
	margin: 0 0 0 10px;
}
.contact_form .form-box .button-group {
	margin-top: 25px;
}
@media (max-width: 991px) {
	.course_feature .img-box {
		margin-right: 0%;
	}
	.contact_form .form-box {
		margin: 0;
	}
}
@media (max-width: 575px) {
	.course_feature {
		padding: 60px 0 0;
	}
	.core-value .owl-nav {
		display: none;
	}
	.course_feature .content-wrapper {
		padding: 20px 20px 30px 20px;
	}
	.course_feature .content-wrapper .details-and-price {
		display: block;
	}
	.course_feature .content-wrapper .right-box {
		text-align: left;
	}
	.core-value {
		padding: 60px 0 0;
	}
	.subscription_sec {
		padding: 30px 0 0px;
	}
	.subscription_sec .subscribe-box {
		padding: 30px 15px 30px 15px;
	}
	.contact_form .form-box .form-group label {
		font-size: 10px;
	}
	.contact_form .form-box {
		padding: 30px 20px 25px 20px;
	}
}
/* Lead Course Css End Here */
