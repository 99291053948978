/* Thank you sec css start here  */
section.inner-banner-sec {
	position: relative;
}
.inner-stuff-all {
	position: absolute;
	z-index: 999;
	top: 159px;
	left: 0;
	right: 0;
	bottom: unset;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
}
.inner-banner-img img {
	width: 100%;
}
.inner-banner-head h2 {
	font-size: 42px;
	color: #ffff;
	font-family: "poppins-bold";
	font-weight: 600;
}
.inner-anchor a {
	font-size: 15px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 500;
	text-decoration: none;
}
.inner-anchor {
	text-align: center;
}
/* End of Inner banner section  */

/* Thank You Css Start Here */
.thankyou-sec {
	padding: 60px 0;
}
.thankyou-sec .head {
	text-align: center;
}
.thankyou-sec .head h1 {
	font-size: 45px;
	color: #f6a309;
	font-family: "poppins-bold";
	font-weight: 700;
	margin-bottom: 30px;
}
.thankyou-sec .head h4 {
	font-size: 22px;
	color: #262626;
	font-family: "poppins-semibold";
	font-weight: 700;
}
.thankyou-sec .order-nmber {
	text-align: center;
}
.thankyou-sec .order-nmber h4 {
	font-size: 18px;
	color: #f6a309;
	font-family: "poppins-regular";
	font-weight: 600;
	padding: 5px 0;
}
.thankyou-sec .order-detail {
	border: 1px solid #707070b3;
	padding: 40px 40px;
}
.thankyou-sec .order-detail h5 {
	font-size: 20px;
	color: #262626;
	padding-bottom: 5px;
	font-family: "poppins-bold";
	font-weight: 700;
}
.thankyou-sec .order-detail ul {
	margin-bottom: 0;
	padding: 0;
	list-style: none;
}
.thankyou-sec .order-detail ul li {
	padding: 6px 0;
}
.thankyou-sec .order-detail ul li span.property {
	font-size: 15px;
	color: #000;
	font-weight: 400;
}
.thankyou-sec .order-detail ul li span.value {
	font-size: 15px;
	color: #000;
	font-weight: 400;
	font-family: "poppins-regular";
}
.thankyou-sec .order-detail .shipment-address {
	padding-bottom: 20px;
	border-bottom: 1px solid #707070b3;
}
.thankyou-sec .order-detail .payment-detail {
	padding: 30px 0;
}
.thankyou-sec .order-detail ul.payment-total li {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.thankyou-sec .order-detail ul.address li {
	display: flex;
	align-items: center;
}
.thankyou-sec .order-detail ul.address li span.property {
	width: 100px;
}
.thankyou-sec .order-detail .order-total {
	padding-top: 20px;
	border-top: 1px solid #c5c5c5;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.thankyou-sec .order-detail .order-total h3.property {
	color: #f6a309;
	font-size: 20px;
	font-family: "poppins-bold";
	font-weight: 600;
}
.thankyou-sec .order-detail .order-total h3.value {
	color: #f6a309;
	font-size: 20px;
	font-family: "poppins-bold";
	font-weight: 500;
}
.thankyou-sec .order-detail ul.payment-total li span.property {
	color: #000000;
	font-family: "poppins-semibold";
}
.thankyou-sec .order-detail ul.payment-total li span.value {
	color: #000;
	font-weight: 400;
}
.thankyou-sec a {
	margin-top: 15px;
	background: #f6a309;
	color: #fff !important;
	font-size: 18px;
	font-weight: 600;
	width: 100%;
	padding: 15px 0;
	transition: 0.6s;
	border: 2px solid #fff;
	text-transform: uppercase;
	letter-spacing: 2px;
}
.thankyou-sec a.btn:hover {
	box-shadow: 0px 0px 0px 2px #f6a309;
	opacity: 1;
	background-color: #f6a309;
}
/* Thank You Css End Here */

/* Media Query Start Here */
@media (max-width: 1100px) {
	.thankyou-sec .order-detail ul li span.value {
		font-size: 14px;
	}
	.thankyou-sec .order-detail ul li span.property {
		font-size: 14px;
	}
}
@media (max-width: 768px) {
	.thankyou-sec .head h1 {
		font-size: 25px;
	}
	.thankyou-sec .head h4 {
		font-size: 18px;
	}
	.thankyou-sec .order-nmber h4 {
		font-size: 20px;
		padding: 10px 0;
	}
}
@media (max-width: 450px) {
	.thankyou-sec .order-detail {
		padding: 20px 10px;
	}
	.thankyou-sec .order-detail ul li span.value {
		font-size: 11px;
	}
	.thankyou-sec .order-detail ul li span.property {
		font-size: 11px;
	}
	.thankyou-sec .order-detail ul.address li span.property {
		width: 70px;
	}
	.thankyou-sec .order-detail ul.address li {
		flex-direction: column;
		align-items: flex-start;
	}
}
/* Thank you sec css end here  */
