.main_img {
	height: 100vh;
	width: 100%;
	background-image: url("../img/story-image.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	padding: 27px 30px;
	min-height: 100%;
}

.form_start input,
.form_start select,
.form_start textarea {
	width: 100%;
	display: block;
	background: #f3f3f3;
	height: 42px;
	padding: 0px 19px 0px;
	font-size: 12px;
	border: 1px solid #c3cad9;
	font-family: var(--font-default);
	/* text-transform: uppercase; */
	outline: none;
	text-align: left;
	color: #6360606d;
	border-radius: 0px;
}

.form_start input::placeholder,
.form_start select,
.form_start textarea::placeholder {
	text-transform: capitalize;
}

.auth_start .wraper .top_text h4 span {
	color: var(--theme-color-1);
}

@media (min-width: 1200px) {
	.auth_start .wraper .top_text h4 span {
		font-size: 25px;
	}

	h2 {
		font-size: 43px;
	}

	h4 {
		font-size: 25px;
	}
}

.auth_start {
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	justify-content: center;
	width: 100%;
	height: 100%;
}

.auth_start .wraper {
	width: 100%;
}

.auth_start .wraper .top_text h5 {
	font-size: 22px;
}

/* Custom Checkbox Start here */
.custom_check_box {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-family: var(--checkbox-color);
	font-size: 14px;
	color: var(--checkbox-color);
}

/* Hide the browser's default checkbox */
.custom_check_box input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.custom_check_box .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 18px;
	width: 19px;
	background-color: #eee;
	border: 1px solid #636060;
	border-radius: 0px;
	background: transparent;
}

section.checkout_section .checkout_start .save_details {
	margin-top: 14px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.custom_check_box input:checked ~ .checkmark {
	background: transparent;
}

.custom_check_box input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.custom_check_box .checkmark:after {
	left: 6px;
	top: 0px;
	width: 5px;
	height: 12px;
	border: solid #000;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* Custom Check Box End Here */
.auth_start .wraper .save_password {
	display: flex;
	justify-content: space-between;
	align-items: self-start;
	margin-top: 19px;
}
.auth_start .wraper .save_password .form-check input {
	width: auto;
}
.auth_start .wraper .save_password .form-check {
	display: flex;
	align-items: center;
	padding: 0;
}
.auth_start .wraper .save_password .form-check label {
	margin: 0 0 0 10px;
}
.form_row {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.form_row .single_input {
	width: 49%;
}

.form_row .otp_input {
	width: 15%;
}

section.auth.login.forgot_password.text-center .logo_img {
	margin-bottom: 66px;
}

.form_row .the_input {
	text-align: left;
	position: relative;
}

.form_row .the_input label {
	margin-bottom: 6px;
	font-family: var(--font-default);
	font-size: 13px;
	color: #1f162c;
}

.nav.nav-tabs.tabs_btns li {
	width: 50%;
	margin: -1px 0px -2px;
}

.nav.nav-tabs.tabs_btns li button {
	width: 100%;
	border-radius: 4px;
	background: transparent;
	color: #191300 !important;
	font-size: 16px;
	box-shadow: none;
	font-family: var(--font-poppin-medium) !important;
}

.nav.nav-tabs.tabs_btns li button.active {
	background: #138fcb;
	color: #fff !important;
}

.custom_check_box1 {
	display: block;
	position: relative;
	padding-left: 25px;
	margin-bottom: 15px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-family: var(--font-default);
	font-size: 13px;
	color: #1f162c;
	text-align: start;
}

/* Hide the browser's default checkbox */
.custom_check_box1 input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.custom_check_box1 .checkmark1 {
	position: absolute;
	top: 0;
	left: 0;
	height: 18px;
	width: 19px;
	background-color: #eee;
	border: 1px solid #1190cb;
	border-radius: 5px;
	background: transparent;
}

section.checkout_section .checkout_start .save_details {
	margin-top: 14px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark1:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.custom_check_box1 input:checked ~ .checkmark1 {
	background: #1190cb;
}

.custom_check_box1 input:checked ~ .checkmark1:after {
	display: block;
}

/* Style the checkmark/indicator */
.custom_check_box1 .checkmark1:after {
	left: 5px;
	top: 1px;
	width: 5px;
	height: 11px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* Custom Check Box End Here */

.all_check_boxes {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.all_check_boxes .single_check_box {
	width: 22%;
}

.accept_payments .the_input.w-100.options {
	display: flex;
	justify-content: space-between;
}

.accept_payments .the_input.w-100.options span {
	font-family: var(--font-default);
	font-size: 13px;
	color: #1f162c;
}

.terms-lines a {
	font-family: var(--font-default);
	color: var(--extra-color-2);
}

@media (min-width: 991px) {
	section.auth.signup .auth_start .for_over_flow_scroll {
		max-height: 622px;
		overflow: scroll;
		width: 100%;
	}

	section.auth.signup .auth_start .for_over_flow_scroll::-webkit-scrollbar {
		display: none;
	}
}

@media (max-width: 1200px) {
	.all_check_boxes .single_check_box {
		width: 25%;
	}
}

@media (max-width: 991px) {
	.all_check_boxes .single_check_box {
		width: 33%;
	}
	.order_md_1 {
		order: 1;
	}
}

@media (max-width: 768px) {
	.auth_start {
		margin-top: 45px;
	}
}

@media (max-width: 576px) {
	.all_check_boxes .single_check_box {
		width: 50%;
	}

	.form_row .single_input {
		width: 100%;
	}

	.form_row .three_inputs {
		width: 100%;
	}
}

@media (min-width: 1200px) {
	.auth_start .wraper p.email_text {
		max-width: 328px;
		width: 100%;
		margin: 15px auto;
	}
}

/*Dropdown List Exp Date*/

select.list-dt:focus {
	border-bottom: 2px solid skyblue;
}

/*The background card*/

/*Imaged Radio Buttons*/
.radio-group {
	position: relative;
	margin-bottom: 25px;
}

.radio {
	display: inline-block;
	width: 204;
	height: 104;
	border-radius: 0;
	background: lightblue;
	box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	cursor: pointer;
	margin: 8px 2px;
}

.radio:hover {
	box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.radio.selected {
	box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
}

/*Fit image in bootstrap div*/
.fit-image {
	width: 100%;
	object-fit: cover;
}

.side-image {
	position: relative;
}

.side-image .auth-slider {
	position: absolute;
	bottom: 15px;
	width: calc(87% + 5px);
	left: 6%;
}

.side-image .auth-slider .slider-list .single-slide {
	background: var(--extra-color-1);
	margin: auto;
	padding: 23px 14px;
}

.side-image .auth-slider .slider-list .single-slide p.username {
	margin-bottom: 0px;
	font-family: var(--font-poppin-semibold);
	font-size: 15px;
	font-style: unset;
}

.side-image .auth-slider .slider-list .single-slide span {
	color: var(--dark-text-color);
	font-family: var(--font-light);
	font-size: 13px;
}

.side-image .auth-slider .slider-list .single-slide p {
	font-family: var(--font-light);
	font-style: italic;
	font-size: 13px;
	line-height: 1.2;
}

.side-image .auth-slider button.slick-arrow {
	width: 25px;
	height: 25px;
	background-size: 100%;
	background-repeat: no-repeat;
}

.slick-prev:before,
.slick-next:before {
	content: unset !important;
}
.auth_start .logo {
	display: none;
}
@media (max-width: 991px) {
	.auth_start {
		padding-bottom: 30px;
		margin-top: 30px;
	}
	.auth_start .wraper .save_password {
		align-items: center;
	}
}
@media (max-width: 768px) {
	.auth_start .logo {
		display: block;
		padding-bottom: 25px;
	}
	.side-image {
		display: none;
	}
	.auth_start {
		padding-bottom: 60px;
	}
	.auth_start .wraper .save_password .form-check label {
		margin: 0 0 0 10px;
		font-size: 14px;
	}
	.auth_start .wraper .save_password a {
		font-size: 14px;
	}
}
