/* Contact Css Start Here */
.contact_sec {
	padding: 70px 0;
}
.contact_sec {
	padding: 70px 0;
}
.contact_sec .contact-box {
	background: #f8f8f8;
	padding: 30px 30px;
	border-radius: 10px;
}
.contact_sec .contact-box .contact-info {
	background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
		url("../img/contact-info-img.png");
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	padding: 30px 30px;
	background-position: top;
}
.contact_sec .contact-box .contact-info ul.info {
	padding: 0 0;
	margin: 0;
	list-style: none;
}
.contact_sec .contact-box .contact-info ul.info li {
	margin-bottom: 15px;
}
.contact_sec .contact-box .contact-info ul.info li a {
	color: #fff;
}
.contact_sec .contact-box .contact-info ul.info li.social-icons .seprator {
	margin: 0 5px;
}
.contact_sec .contact-box .form-control {
	border-radius: 0;
	border: 1px solid #ddd;
	font-size: 14px;
	height: 50px;
}
.contact_sec .contact-box .form-control::placeholder {
	color: #a6a6a6;
}
.contact_sec .contact-box textarea {
	height: 150px !important;
	resize: none;
}
@media (max-width: 991px) {
	.contact_sec {
		padding: 50px 0 0;
	}
	.contact_sec .contact-box form {
		padding-top: 30px;
	}
	.faqs_sec .card .card-header button {
		white-space: break-spaces;
	}
}
@media (max-width: 480px) {
	.contact_sec .contact-box {
		padding: 20px 15px;
	}
	.contact_sec .contact-box .contact-info {
		padding: 20px 15px;
	}
	.contact_sec .contact-box .contact-info ul.info li a {
		color: #fff;
		word-wrap: break-word;
	}
	.faqs_sec {
		padding: 50px 0 0;
	}
	.faqs_sec .card .card-header button {
		font-size: 14px;
	}
}
/* Contact Css End Here */
