/* Shop Css Start Here */
.educational_sec {
	padding: 60px 0;
}
.educational_sec .content-wrapper p {
	font-size: 15px;
}

.educational_sec .img-box {
	overflow: hidden;
}
.educational_sec .img-box img {
	transition: 0.6s;
}
.educational_sec .img-box:hover img {
	transform: scale(1.2);
}
@media (max-width: 991px) {
	.educational_sec {
		padding-bottom: 0;
	}
}
@media (max-width: 575px) {
	.product_sec {
		padding: 60px 0 0;
	}
}
/* Shop Css End Here */
