/* Bc System Css Start Here */
.bc-inner-banner .img-box img {
	width: 100%;
	height: 550px;
	object-fit: cover;
	object-position: center center;
}
.card-boxes {
	padding: 60px 0px;
}
.card-boxes .card-box {
	background: #003b7a;
	text-align: center;
	padding: 20px 20px;
	border-radius: 9px;
	color: #fff;
}
.card-boxes .card-box .txt h3 {
	font-size: 20px;
	line-height: 31px;
}
.card-boxes .card-box .txt p {
	font-size: 14px;
	line-height: 24px;
	min-height: 100px;
}
.enquiry-btn {
	text-align: center;
	padding: 20px 0 20px;
}
.enquiry-btn .primary-btn {
	margin: 0 7px;
	outline: none;
	cursor: pointer;
}
.step_sec {
	padding: 60px 0;
}
.step_sec .content-box {
	position: relative;
}
.step_sec .content-box .txt-box {
	background: #fff;
	box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
	padding: 20px;
	max-width: 90%;
}
.step_sec .content-box .number h2 {
	color: #fdd7dd;
	font-weight: 700;
	font-size: 170px;
	position: absolute;
	top: -50px;
	left: -70px;
	z-index: -1;
	font-family: "poppins-bold";
}
.step-two {
	background: #e7f3ff;
}
.step-two .content-box .txt-box {
	margin: 0 0 0 auto;
	position: relative;
	z-index: 2;
}
.step-two .content-box .number h2 {
	top: -60px;
	left: 0px;
	z-index: 2;
}
.step-two .enquiry-btn {
	padding: 60px 0 0;
}
.enquiry_today_sec {
	padding: 60px 0;
}
.enquiry_today_sec .content-wrapper {
	max-width: 85%;
}
.enquiry_today_sec .img-box {
	max-width: 90%;
	margin: 0 0 0 auto;
}
.enquiry_today_sec .enquiry-btn {
	padding-top: 60px;
}
@media (max-width: 991px) {
	.bc-inner-banner .img-box img {
		height: 400px;
	}
	.card-boxes {
		padding: 60px 0px 20px;
	}
	.card-boxes .card-box {
		margin-bottom: 10px;
	}
	.enquiry_today_sec {
		text-align: center;
	}
	.enquiry-btn .primary-btn {
		width: 80%;
		margin: 10px auto 0;
	}
	.enquiry_today_sec .content-wrapper {
		max-width: 100%;
	}
	.enquiry_today_sec .img-box {
		max-width: 50%;
		margin: 30px auto 0px;
	}
	.step_sec .content-box .txt-box {
		max-width: 100%;
		text-align: center;
		margin: 5px 0 0;
	}
	.step_sec .content-box .number h2 {
		position: unset !important;
		line-height: 180px;
		text-align: center;
	}
	.step_sec .mobile-image {
		max-width: 50%;
		margin: 0px auto;
	}
	.step-one .row {
		flex-direction: column-reverse;
	}
	.step-three .row {
		flex-direction: column-reverse;
	}
	.enquiry_today_sec {
		overflow-x: hidden;
	}
}
@media (max-width: 575px) {
	.bc-inner-banner .img-box img {
		height: 200px;
	}
	.enquiry_today_sec .img-box {
		max-width: 80%;
	}
}
/* Bc System Css End Here */
