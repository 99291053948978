/* Product Cart Css Start Here */
.wishlist-sec {
	padding: 100px 0px;
}
.wishlist-sec .wishlist-wrapper td {
	vertical-align: middle;
}
.wishlist-sec .wishlist-wrapper .product-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}
.wishlist-sec .wishlist-wrapper .product-wrapper img {
	height: 70px;
	width: 70px;
	border-radius: 50%;
	object-fit: cover;
}
.wishlist-sec .wishlist-wrapper td.product-name {
	width: 18%;
}
.wishlist-sec .wishlist-wrapper td.close-col button {
	border: 0px;
	background: transparent;
	color: #c59531;
	font-size: 22px;
}
.wishlist-sec .wishlist-wrapper tr td {
	text-align: center;
	padding: 21px 10px;
}
.wishlist-wrapper thead th {
	text-align: center;
}
.wishlist-sec .wishlist-wrapper .product-wrapper h6 {
	color: #1f2c35;
	font-size: 15px;
	font-weight: 500;
	margin: 0px;
}
.wishlist-sec .wishlist-wrapper td.price-name h6,
.wishlist-sec .wishlist-wrapper td.stock-name h6,
.wishlist-sec .wishlist-wrapper td.product-name h6 {
	color: #000000;
	font-size: 15px;
	font-weight: 600;
	margin: 0px;
}
.wishlist-sec .wishlist-wrapper .table thead th {
	border: 0px;
	color: #4a4a4a;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	padding: 17px 0px;
	font-family: "poppins-bold";
}
.wishlist-sec .wishlist-wrapper .table thead th.pro-img {
	text-align: left;
}
.wishlist-sec .wishlist-wrapper td.product-name .btn {
	padding: 10px 19px;
	font-size: 12px;
	background-image: linear-gradient(
		90deg,
		hsl(46deg 96% 57%) 0%,
		hsl(46deg 92% 62%) 9%,
		hsl(47deg 87% 66%) 18%,
		hsl(48deg 81% 70%) 27%,
		hsl(48deg 73% 71%) 36%,
		hsl(45deg 65% 63%) 45%,
		hsl(43deg 60% 56%) 55%,
		hsl(41deg 57% 50%) 64%,
		hsl(41deg 57% 52%) 73%,
		hsl(42deg 61% 59%) 82%,
		hsl(44deg 66% 66%) 91%,
		hsl(46deg 75% 73%) 100%
	);
}
.wishlist-sec .btn-wrapper button.btn-Clear {
	padding: 8px 40px;
	background-repeat: no-repeat;
	border-radius: 0px;
	color: #343436;
	font-weight: 500;
	margin-left: 27px;
	font-size: 18px;
	border: 1px solid #343436;
	background: transparent;
}
.wishlist-sec .wishlist-wrapper {
	border: 1px solid #e6e6e6;
	padding: 30px;
	border-radius: 7px;
	box-shadow: 0px 10px 15px 1px rgb(0 0 0 / 10%);
}
.wishlist-sec .btn-wrapper {
	margin-top: 30px;
	text-align: end;
}
.wishlist-wrapper td.stock-name .counter-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}
.wishlist-wrapper td.stock-name .counter-wrapper input[type="number"] {
	width: 66px;
	height: 33px;
	background: #f2f2f2;
	border: 0px;
	text-align: center;
}
.wishlist-wrapper
	td.stock-name
	.counter-wrapper
	input[type="number"]::-webkit-outer-spin-button,
.wishlist-wrapper
	td.stock-name
	.counter-wrapper
	input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
.wishlist-wrapper td.stock-name .counter-wrapper .counter-btn-wrapper {
	display: flex;
	flex-direction: column;
}
.wishlist-wrapper td.stock-name .counter-wrapper .counter-btn-wrapper button {
	height: 16px;
	border: 0px;
	font-size: 11px;
	margin: 1px;
	color: #000;
	border: 0;
	box-shadow: none;
	cursor: pointer;
	outline: none;
}
.wishlist-sec .discount-row-wrapper .copon-wrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	background: #eeeeee;
	padding: 10px;
	border-radius: 4px;
}
.wishlist-sec .discount-row-wrapper .copon-wrapper label {
	flex: 0 0 22%;
	color: #525252;
	font-size: 14px;
	margin: 0px;
	font-family: "muli-reg";
}
.wishlist-sec .discount-row-wrapper .copon-wrapper .input-group {
	flex: 0 0 78%;
	justify-content: space-between;
}
.wishlist-sec .discount-row-wrapper .copon-wrapper .input-group input {
	height: 42px;
	background: #ffffff;
	border-radius: 0;
	border: 0px;
	background: transparent;
	border-bottom: 1px solid #b5b5b5;
	flex: 0 0 68%;
}
.wishlist-sec .discount-row-wrapper .copon-wrapper span.input-group-btn {
	flex: 0 0 27%;
}
.wishlist-sec
	.discount-row-wrapper
	.copon-wrapper
	.input-group
	input::placeholder {
	color: #a8a8a8;
	font-weight: 400;
}
.wishlist-sec .discount-row-wrapper .copon-wrapper .input-group button.btn {
	height: 41px;
	font-weight: 500;
	font-size: 15px;
	background: #9a3407;
	padding: 10px 21px;
	color: #fff;
	border-radius: 6px;
}
.wishlist-wrapper .discount-wrapper .discount-list-wrapper ul {
	list-style: none;
	padding: 0px;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #878787;
}
.wishlist-wrapper .discount-wrapper .discount-list-wrapper ul li {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.wishlist-wrapper .discount-wrapper .discount-list-wrapper ul li span {
	text-align: right;
	flex: 0 0 50%;
	color: #989898;
	font-size: 12px;
	font-family: "poppins-regular";
}
.wishlist-wrapper .discount-wrapper .total-cost-wrapper ul {
	padding: 0px;
	list-style: none;
}
.wishlist-wrapper .discount-wrapper .total-cost-wrapper ul li {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.wishlist-wrapper .discount-wrapper .total-cost-wrapper ul li span {
	flex: 0 0 50%;
	text-align: right;
	font-size: 18px;
	color: #000000;
	font-family: "poppins-regular";
	font-weight: 600;
}
.wishlist-sec .wishlist-wrapper .table > :not(:first-child) {
	border-top: 1px solid #c5c5c5;
}
.wishlist-sec .wishlist-wrapper .product-wrapper h6 {
	color: #1f2c35 !important;
	font-family: "poppins-regular" !important;
	font-size: 13px !important;
}
.wishlist-sec .wishlist-wrapper td.price-name h6,
.wishlist-sec .wishlist-wrapper td.product-name h6 {
	font-family: "poppins-regular" !important;
	font-size: 15px !important;
}
.wishlist-sec .wishlist-wrapper .table thead th.pro-img {
	text-align: left;
}

.wishlist-sec .wishlist-wrapper tr td.product-img {
	padding: 21px 0px;
	text-align: left;
}
.wishlist-sec .wishlist-wrapper tr td.product-img img {
	width: 60px;
}
.wishlist-sec
	.discount-row-wrapper
	.copon-wrapper
	.input-group
	input::placeholder {
	color: #343436;
	font-size: 12px;
	font-weight: 700;
	font-family: "poppins-bold";
}
.wishlist-sec .btn-wrapper a.btn {
	background: #ed9521;
	font-size: 16px;
	color: #fff;
	font-weight: 400;
	padding: 10px 20px;
}
.wishlist-sec .btn-wrapper.fst-btn {
	text-align: left;
}
.wishlist-sec .btn-wrapper.fst-btn .btn {
	background: #000;
	font-size: 16px;
	color: #fff;
	font-family: "poppins-regular";
	font-weight: 400;
	padding: 10px 20px;
}
.wishlist-sec .btn-wrapper.fst-btn .btn-Clear {
	background: #eeeeee;
	font-size: 16px;
	color: #343436;
	font-weight: 700;
	padding: 10px 20px;
	border: 0px;
	width: 182px;
	height: 46px;
	border-radius: 0.25rem;
}
.wishlist-sec .btn-wrapper p {
	color: #a1a1a1;
	font-size: 14px;
	font-family: "muli-reg";
	font-weight: 400;
}
.wishlist-sec .heading-wrapper {
	text-align: center;
	padding: 0px 0px 40px 0px;
}

.wishlist-sec .heading-wrapper h4 {
	color: #2a263d;
	font-family: "nunito-bold";
	font-size: 45px;
	font-weight: 700;
}
thead,
tbody tr {
	display: table;
	width: 100%;
	table-layout: fixed;
}
@media (max-width: 991px) {
	.wishlist-sec .wishlist-wrapper tr td {
		width: 180px !important;
	}
	.wishlist-sec .btn-wrapper {
		margin-top: 5px;
	}
}
/* Product Cart Css End Here */
