/* Product Detail Css Start Here */
.product-detail {
	padding: 60px 0px;
	background: #f3f3f3;
}
.product-detail .row {
	background: #fff;
	padding: 35px 30px;
	border-radius: 10px;
}
.product-detail .product-breadcrumbs-wrapper ul {
	list-style: none;
	padding: 0px;
	display: flex;
	flex-wrap: wrap;
}
.product-detail .product-breadcrumbs-wrapper ul li a {
	font-size: 12px;
	margin: 0px 10px 0px 0px;
	text-decoration: none;
	color: #666666;
	font-weight: 400;
}
.product-detail .product-breadcrumbs-wrapper ul li a.active {
	color: #000000;
}
.product-detail .product-detail-content-wrapper .detail-wrapper h3 {
	font-size: 24px;
	font-family: "nunito-bold";
	font-weight: 700;
	color: #000;
}
.product-detail .product-detail-content-wrapper .detail-wrapper h4 {
	font-size: 20px;
	font-family: var(--font-nunito-medium);
	font-weight: 400;
	color: #000;
}
.product-detail .product-detail-content-wrapper .reviews-wrapper ul {
	display: flex;
	list-style: none;
	padding: 0px;
}
.product-detail .product-detail-content-wrapper .reviews-wrapper ul li i {
	color: #ffab00;
	margin: 0px 2px 0px 0px;
}
.product-detail
	.product-detail-content-wrapper
	.reviews-wrapper
	ul
	li
	span.total-reviews {
	font-family: "poppins-bold";
	font-size: 14px;
	color: #afafaf;
	font-weight: 400;
	text-transform: uppercase;
	margin-left: 12px;
}
.product-detail .seller-card-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f3f3f3;
	padding: 20px 12px;
	border-radius: 6px;
}
.product-detail .seller-card-wrapper img {
	height: 55px;
	width: 55px;
	border: 1px solid #fff;
	border-radius: 50%;
	margin-right: 10px;
}
.product-detail .seller-card-wrapper .seller-content-wrapper h4 {
	font-size: 21px;
	font-family: "Circular-Loom";
	font-weight: 600;
	color: #000;
}
.product-detail .seller-card-wrapper .seller-content-wrapper h6 {
	color: #2a2a2a;
	font-size: 12px;
	font-family: "poppins-semibold";
	font-weight: 600;
}
.product-detail .seller-card-wrapper figure {
	margin: 0px;
}
.product-detail
	.seller-card-wrapper
	.seller-content-wrapper
	h6
	span:last-child {
	margin-left: 6px;
}
.product-detail .become-seller {
	text-align: center;
}
.product-detail .become-seller h6 {
	font-size: 11px;
	margin-top: 27px;
	color: #666666;
	font-weight: 400;
}
.product-detail .become-seller h6 a {
	color: #d5920a;
	text-decoration: none;
}
.product-detail
	.product-detail-content-wrapper
	.detail-wrapper
	.best-seller-wrapper
	h4 {
	background: #e6e6e6;
	display: inline-block;
	font-size: 14px;
	font-weight: 700;
	font-family: "poppins-bold";
	padding: 7px 12px;
	border-radius: 6px;
	color: #000;
	display: inline-flex;
	align-items: center;
}
.product-detail
	.product-detail-content-wrapper
	.detail-wrapper
	.best-seller-wrapper
	h4
	span {
	margin-right: 10px;
}
.product-detail .product-detail-content-wrapper .price-wrapper {
	display: flex;
	align-items: center;
	padding-bottom: 15px;
}
.product-detail .description p {
	font-size: 13px;
	line-height: 23px;
}
.product-detail .product-detail-content-wrapper .price-wrapper h4 {
	font-size: 23px;
	padding-right: 15px;
	margin-bottom: 0;
}
.product-detail .product-detail-content-wrapper .price-wrapper h3 {
	color: #669900;
	font-size: 20px;
	font-weight: 700;
	font-family: "poppins-bold";
	margin: 10px 0px;
}
.product-detail-content-wrapper .quantity-card-wrapper {
	display: flex;
	align-items: end;
	margin-top: 10px;
}
.product-detail-content-wrapper .quantity-card-wrapper .quantity-wrapper h4 {
	font-size: 14px;
	font-family: "poppins-regular";
	font-weight: 400;
	color: #222222;
}
.product-detail-content-wrapper
	.quantity-card-wrapper
	.quantity-wrapper
	.quantity-input-wrapper {
	border: 1px solid #dddddd;
	height: 46px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 10px;
	border-radius: 4px;
}
.product-detail-content-wrapper
	.quantity-card-wrapper
	.quantity-wrapper
	.quantity-input-wrapper
	button {
	border: 0px;
	background: transparent;
	font-size: 19px;
	cursor: pointer;
	box-shadow: none;
	outline: none;
	border: 0;
}
.product-detail-content-wrapper
	.quantity-card-wrapper
	.quantity-wrapper
	.quantity-input-wrapper
	input {
	border: 0px;
	text-align: center;
	width: 57px;
	color: #333333;
	font-size: 13px;
	font-family: "poppins-bold";
	font-weight: 700;
}
.product-detail-content-wrapper
	.quantity-card-wrapper
	.quantity-wrapper
	.quantity-input-wrapper
	input::-webkit-inner-spin-button,
.product-detail-content-wrapper
	.quantity-card-wrapper
	.quantity-wrapper
	.quantity-input-wrapper
	input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.btn-wrapper {
	margin-top: 25px;
}
.btn-wrapper .btn {
	background: #fab528;
	height: 46px;
	font-family: "poppins-regular";
	font-size: 15px;
	font-weight: 700;
	margin-right: 22px;
	padding: 11px 70px;
	border-radius: 6px;
	color: #000;
}
.product-detail .product-detail-content-wrapper .wishlist-wrapper h5 {
	color: #000000;
	margin: 33px 0px 10px 0px;
	font-family: "poppins-regular";
	font-size: 14px;
	border-bottom: 1px solid #d2d2d2;
	padding-bottom: 16px;
}
.product-detail-content-wrapper .other-deatils-wrapper ul {
	list-style: none;
	padding: 0px;
	border-top: 1px solid #ddd;
	padding-top: 20px;
	margin-top: 25px;
}
.product-detail-content-wrapper .other-deatils-wrapper ul li span {
	color: #666666;
	font-size: 14px;
}
.product-detail-content-wrapper .other-deatils-wrapper ul li span.value {
	margin-left: 10px;
}
.products-tab-wrapper {
	padding: 100px 0px;
}
.products-tab-wrapper .details-tab-wrapper ul#myTab {
	display: block;
	border: 0px;
	background: #f6f6f6;
	padding: 20px;
}
.products-tab-wrapper .details-tab-wrapper ul#myTab li.nav-item:first-child {
	border-bottom: 1px solid #e1e1e1;
}
.products-tab-wrapper .details-tab-wrapper ul#myTab li.nav-item a {
	padding: 10px 0;
	background: transparent;
	border: 0;
	font-family: "poppins-medium";
	color: #000;
}
.products-tab-wrapper .details-tab-wrapper ul#myTab li.nav-item button {
	border: 0px;
	background: transparent;
	padding: 13px 0px;
	color: #000;
	border: 0;
	box-shadow: none;
	outline: none;
	cursor: pointer;
}
.products-tab-wrapper .details-tab-wrapper ul#myTab li.nav-item a.active {
	color: #f99e1c;
}
.products-tab-wrapper .details-tab-wrapper ul#myTab li.nav-item button.active {
	color: #fab528;
}
.products-tab-wrapper .description-wrapper p {
	font-size: 14px;
	font-family: "poppins-regular";
	color: #666666;
	margin-bottom: 25px;
}
.products-tab-wrapper .description-wrapper h4 {
	font-size: 18px;
	font-weight: 700;
	font-family: "poppins-bold";
}
.products-tab-wrapper .description-wrapper h5 {
	font-size: 14px;
	font-weight: 700;
	color: #000;
}
.description-wrapper ul {
	padding: 0px;
	list-style: none;
}
.products-tab-wrapper .contains_box .heading-wrapper {
	padding-bottom: 10px;
}
.products-tab-wrapper .contains_box p {
	font-size: 14px;
	font-family: "poppins-regular";
	color: #666666;
	margin-bottom: 25px;
}
.products-tab-wrapper .contains_box ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
.products-tab-wrapper .contains_box ul li {
	font-size: 14px;
	font-family: "poppins-regular";
	color: #666666;
	margin-bottom: 15px;
	padding-left: 25px;
	position: relative;
}
.products-tab-wrapper .contains_box ul li:before {
	content: "";
	background: #fab528;
	width: 10px;
	height: 10px;
	position: absolute;
	left: 0;
	top: 5px;
	border-radius: 50%;
}
.description-wrapper ul li {
	font-size: 13px;
	line-height: 2;
	color: #666666;
}
.products-tab-wrapper .description-wrapper p.yellow {
	color: #fab528;
}
.product-detail .product-slider-wrapper .single-slider {
	flex: 0 0 15%;
}
.product-detail .product-slider-wrapper .main-slider-wrapper {
	flex: 0 0 85%;
}
.product-detail .product-slider-wrapper .single-slider img {
	margin: 0px 0px 10px 0px;
}
.product-detail .product-slider-wrapper .main-slider-wrapper .zoom-wrapper {
	text-align: center;
	padding-top: 16px;
}
.product-detail .product-slider-wrapper .main-slider-wrapper .zoom-wrapper h6 {
	font-family: "poppins-bold";
	color: #5d5d5d;
	font-size: 16px;
}

.product-detail
	.product-slider-wrapper
	.main-slider-wrapper
	.zoom-wrapper
	h6
	i {
	font-size: 17px;
	color: #878787;
}
.add-review-wrapper .form-check-input:checked[type="checkbox"] {
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 strokeLinecap=%27round%27 stroke-linejoin=%27round%27 strokeWidth=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e") !important;
}
.add-review-wrapper .form-check-input {
	margin-left: -16px;
}

.add-review-wrapper .form-check-input:checked {
	background: #71966d;
	border-radius: 0px;
	border-color: #71966d;
}
#full-stars-example-two {
	/* use display:inline-flex to prevent whitespace issues. alternatively, you can put all the children of .rating-group on a single line */
	/* make hover effect work properly in IE */
	/* hide radio inputs */
	/* hide 'none' input from screenreaders */
	/* set icon padding and size */
	/* set default star color */
	/* if any input is checked, make its following siblings grey */
	/* make all stars orange on rating group hover */
	/* make hovered input's following siblings grey on hover */
}
#full-stars-example-two .rating-group {
	display: inline-flex;
	margin-top: -11px;
}
#full-stars-example-two .rating__icon {
	pointer-events: none;
}
#full-stars-example-two .rating__input {
	position: absolute !important;
	left: -9999px !important;
}
#full-stars-example-two .rating__input--none {
	display: none;
}
#full-stars-example-two .rating__label {
	cursor: pointer;
	padding: 0 0.1em;
	font-size: 2rem;
}
#full-stars-example-two .rating__icon--star {
	color: orange;
	font-size: 17px;
}
#full-stars-example-two
	.rating__input:checked
	~ .rating__label
	.rating__icon--star {
	color: transparent;
	font-size: 17px;
	-webkit-text-stroke: 1px #707070;
}
#full-stars-example-two .rating-group:hover .rating__label .rating__icon--star {
	color: orange;
}
#full-stars-example-two
	.rating__input:hover
	~ .rating__label
	.rating__icon--star {
	color: #ddd;
}

.reviews-wrapper .heading-wrapper h5 {
	font-family: "nunito-bold";
	font-size: 30px;
	font-weight: 700;
}
.reviews-wrapper .heading-wrapper p {
	color: #707070;
	font-size: 14px;
	margin-bottom: 36px;
	font-family: "poppins-regular";
}
.reviews-wrapper .single-review-wrapper {
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid #afafaf;
	padding: 30px 0px;
}
.reviews-wrapper .single-review-wrapper .single-img-wrapper {
	flex: 0 0 15%;
}
.reviews-wrapper .single-review-wrapper .single-reviews-content-wrapper {
	flex: 0 0 85%;
}
.reviews-wrapper .single-review-wrapper .single-reviews-content-wrapper h5 {
	font-family: "poppins-bold";
	color: #0c0202;
	font-size: 18px;
	font-weight: 600;
}
.reviews-wrapper .single-review-wrapper .single-reviews-content-wrapper h6 {
	color: #818181;
	font-family: "poppins-regular";
	font-size: 14px;
}
.reviews-wrapper .single-review-wrapper .single-reviews-content-wrapper p {
	color: #5e5e5e;
	font-family: "poppins-regular";
	font-size: 13px;
	line-height: 23px;
}
.add-review-wrapper {
	padding: 50px 0px;
}

.add-review-wrapper .heading-wrapper {
	margin-bottom: 25px;
}

.add-review-wrapper .heading-wrapper h3 {
	color: #232323;
	font-size: 24px;
	font-family: "nunito-bold";
	font-weight: 700;
}

.add-review-wrapper .review-form-wrapper .rate-wrapper {
	height: 43px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #707070;
}

.add-review-wrapper .review-form-wrapper input.form-control {
	color: #707070;
	border-radius: 0px;
	height: 43px;
	font-size: 13px;
	border: 1px solid #707070;
	margin-bottom: 18px;
}
.add-review-wrapper .review-form-wrapper textarea.form-control {
	color: #707070;
	border-radius: 0px;
	font-size: 13px;
	border: 1px solid #707070;
}
.add-review-wrapper .review-form-wrapper .rate-wrapper label {
	font-size: 13px;
	color: #707070;
	padding-left: 14px;
}
.add-review-wrapper .review-form-wrapper button {
	cursor: pointer;
}
.pro-sec .product-card-wrapper span.ice {
	color: #0099ff;
}
@media (max-width: 991px) {
	.product-detail .row {
		padding: 35px 10px;
		border-radius: 0px;
		margin: 0 0;
	}
	.product-detail .product-detail-content-wrapper {
		padding-top: 20px;
	}
	.btn-wrapper .btn {
		margin-right: 10px;
		padding: 11px 45px;
	}
	.products-tab-wrapper {
		padding: 50px 0px;
	}
	.products-tab-wrapper .details-tab-wrapper ul#myTab {
		margin-bottom: 25px;
	}
	.reviews-wrapper .heading-wrapper {
		text-align: center;
	}
	.reviews-wrapper .single-review-wrapper {
		display: block;
		text-align: center;
	}
	.reviews-wrapper .single-review-wrapper .single-img-wrapper {
		margin-bottom: 20px;
	}
	.reviews-wrapper .single-review-wrapper .single-reviews-content-wrapper {
		flex: 0 0 70%;
		margin-left: 15px;
	}
	.add-review-wrapper .review-form-wrapper button {
		margin-top: 20px;
	}
}
@media (max-width: 768px) {
	.wishlist-sec {
		padding: 60px 0px 0;
	}
	.btn-wrapper .btn {
		width: 100%;
		margin-top: 5px;
	}
	.add-review-wrapper {
		padding: 50px 0px 0;
	}
	.add-review-wrapper .form-check label {
		font-size: 11px;
	}
}
@media (max-width: 480px) {
	.wishlist-sec .wishlist-wrapper tr td {
		width: 150px !important;
	}
}
/* Product Detail Css End Here */
