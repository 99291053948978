/* Our Story Css Start Here */
.our_story {
	padding: 60px 0;
}
.our_story .content-wrapper .heading-wrapper {
	padding-bottom: 15px;
}
.our_story .content-wrapper .heading-wrapper h5 {
	color: var(--theme-color-1);
	margin: 0;
}
.our_story .content-wrapper p {
	font-size: 14px;
	line-height: 22px;
}
.our_story .quality-box {
	position: relative;
	margin-top: 20px;
}
.our_story .quality-box .icon-box {
	max-width: 60px;
	margin-bottom: 5px;
}
.our_story .quality-box .icon-box img {
	width: 60px;
}
.our_story .quality-box .content {
	margin-top: 15px;
}
.our_story .quality-box .content .title {
	font-size: 18px;
	font-family: var(--font-poppins-medium);
}
.our_story .quality-box .content p {
	font-size: 15px;
	line-height: 24px;
}
.our_purpose {
	background: #f2f3f2;
	padding: 60px 0;
	margin-top: 100px;
}
.approach_sec {
	margin-top: 200px;
}
.our_purpose .img-box {
	margin-top: -140px;
}
.our_purpose .content-wrapper {
	padding-top: 30px;
}
.our_purpose .content-wrapper h2 {
	padding-bottom: 20px;
}
@media (max-width: 991px) {
	.our_story .reversed-row {
		flex-direction: column-reverse;
	}
	.our_story .content-wrapper {
		padding-top: 20px;
	}
}
/* Our Story Css End Here */
