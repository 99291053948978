/* Packages Css Start Here */
.packages_sec {
	padding: 60px 0;
}
.packages_sec .heading-wrapper {
	padding-bottom: 50px;
	text-align: center;
}
.packages_sec .heading-wrapper .title {
	font-size: 60px;
	line-height: 70px;
}
.packages_sec .row {
	max-width: 80%;
	margin: 0px auto;
}
.packages_sec .package-box {
	border: 1px solid #ddd;
	border-radius: 6px;
	margin-bottom: 20px;
	padding: 0 0 40px;
}
.packages_sec .package-box .title {
	background: #000;
	padding: 15px 0;
	color: #fff;
	text-align: center;
	border-radius: 5px 5px 0 0;
}
.packages_sec .package-box .title h3 {
	margin: 0;
}
.packages_sec .package-box .price {
	padding: 0 50px;
	text-align: center;
}
.packages_sec .package-box .price h3 {
	border-bottom: 1px solid #ddd;
	padding-bottom: 15px;
	padding-top: 15px;
	color: #f6a309;
	font-size: 35px;
	line-height: 45px;
}
.packages_sec .package-box ul.list {
	padding: 0 0;
	list-style: none;
	margin: 0 10px;
	text-align: center;
	overflow-y: auto;
	height: 150px;
}
.packages_sec .package-box ul.list::-webkit-scrollbar {
	width: 0.3em;
}
.packages_sec .package-box ul.list::-webkit-scrollbar-thumb {
	background-color: #f6a309;
}
.packages_sec .package-box ul.list li {
	margin: 10px 0;
}
.packages_sec .package-box .button-group {
	margin-top: 40px;
	text-align: center;
}
@media (max-width: 575px) {
	.packages_sec {
		padding: 40px 0 0px;
	}
	.packages_sec .heading-wrapper .title {
		font-size: 40px;
		line-height: 50px;
	}
	.packages_sec .row {
		max-width: 100%;
	}
}
@media (max-width: 414px) {
	.packages_sec .heading-wrapper {
		padding-bottom: 20px;
	}
	.packages_sec .heading-wrapper .title {
		font-size: 30px;
		line-height: 40px;
	}
	.packages_sec .package-box ul.list {
		height: auto;
	}
	.packages_sec .package-box ul.list li {
		font-size: 13px;
	}
	.packages_sec .package-box .button-group {
		margin-top: 20px;
	}
}
/* Packages Css End Here */
