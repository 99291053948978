.spinner_container {
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
/* .spinner_container > div {
    color: #17723e; */
/* } */