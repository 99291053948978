/* All Variable Css Start Here */
@font-face {
	font-family: "nunito-bold";
	src: url("../fonts/Nunito-Bold.ttf");
}
@font-face {
	font-family: "nunito-semibold";
	src: url("../fonts/Nunito-SemiBold.ttf");
}
@font-face {
	font-family: "nunito-medium";
	src: url("../fonts/Nunito-Medium.ttf");
}
@font-face {
	font-family: "nunito-regular";
	src: url("../fonts/Nunito-Regular.ttf");
}
@font-face {
	font-family: "nunito-light";
	src: url("../fonts/Nunito-Light.ttf");
}
@font-face {
	font-family: "poppins-bold";
	src: url("../fonts/Poppins-Bold.ttf");
}
@font-face {
	font-family: "poppins-semibold";
	src: url("../fonts/Poppins-SemiBold.ttf");
}
@font-face {
	font-family: "poppins-medium";
	src: url("../fonts/Poppins-Medium.ttf");
}
@font-face {
	font-family: "poppins-regular";
	src: url("../fonts/Poppins-Regular.ttf");
}
@font-face {
	font-family: "poppins-light";
	src: url("../fonts/Poppins-Light.ttf");
}

:root {
	/* Typography starts here */
	--font-default: "poppins-regular";
	--font-heading: "nunito-bold";
	--font-paragraph: "poppins-regular";
	--font-btn: "poppins-medium";
	--font-nunito-semibold: "nunito-semibold";
	--font-nunito-medium: "nunito-medium";
	--font-nunito-light: "nunito-light";
	--font-poppins-light: "poppins-light";
	--font-poppins-medium: "poppins-medium";
	--font-poppins-semibold: "poppins-semibold";
	--font-poppins-bold: "poppins-bold";
	/* Typography ends here */

	/* Colors starts here */
	--body-color: #fff;
	--theme-color-1: #f6a309;
	--theme-color-2: #000;
	--theme-color-3: #ebe4d8;
	--default-text-color: #000;
	/* Colors ends here */

	/* button colors starts here */
	--primary-btn-color: #f6a309;
	--primary-btn-hover-back: #000;
	--primary-btn-text-color: #fff;
	/* button colors starts here */
}

/* All Variable Css End Here */
